import React from "react";
import "./ArrowList.scss";

const ArrowList = ({ textHtml, rtl = false, color = 'bleu' }) => {

  return (
    <div className={`arrowlist_component color_${color} ${rtl ? 'rtl rtl-text' : ''}`}
      dangerouslySetInnerHTML={{
        __html: textHtml,
      }
      }
    ></div >
  )
}

export default ArrowList;
