import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import useMedias from '../../../hooks/useMedias';

import './CardSection.scss';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import ProductCard from '../../../Molecules/ProductCard/ProductCard';

let classNames = require('classnames');

const CardSection = ({
  imagesArray,
  section,
  customClass,
}) => {
  const { getImage } = useMedias();
  let cardsArr = [];

  let singleCardDetails = {};
  section?.relationships?.field_blocs?.forEach(cardDetails => {

    singleCardDetails = {};
    singleCardDetails.title = cardDetails?.title;
    singleCardDetails.title_formatted = cardDetails?.field_text?.processed;
    singleCardDetails.subTile = cardDetails?.field_subtitle;
    singleCardDetails.paragraph = cardDetails?.field_bloc_text_1?.processed;
    singleCardDetails.btn = cardDetails?.field_cta;
    singleCardDetails.link = cardDetails?.field_link;
    singleCardDetails.img = getImage(imagesArray, cardDetails?.relationships?.field_image?.drupal_internal__mid)?.uri?.url;
    singleCardDetails.alt = cardDetails?.relationships?.field_image?.field_alt || cardDetails?.relationships?.field_image?.field_media_image?.alt;
    cardsArr.push(singleCardDetails);

  })

  return (
    <div
      className={classNames(
        'cards_section',
        'product_page_component',
        'section_' + section?.relationships?.field_category?.drupal_internal__tid,
        customClass ? customClass : ''
      )}
    >
      {
        section?.field_title?.processed &&
        <div className="wrapper_page_xs">
          <TitleSection
            title={section.field_title.processed}
            type="arrow"
            rtl={section.field_display_rtl || false}
            subtitle={section.field_subtitle?.processed}
          />
        </div>
      }
      {
        cardsArr?.length > 0 &&
        <div className="wrapper_page_xs">
          <ul className={classNames('products_wrapper', section.field_display_rtl ? 'mode_rtl' : '')}>
            {cardsArr?.map((card, i) => (
              <ProductCard card={card} key={i} rtl={section.field_display_rtl || false} htmlTag='li' />
            ))}
          </ul>
        </div>
      }

    </div>
  );
};

CardSection.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  quote: PropTypes.object,
};

export default CardSection;
