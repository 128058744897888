import React, { useState } from 'react';

import { HorizontalYellowLineShape, MaqsueBannerCardProduct } from '../../Atoms/Icons/Icons';
import { Button } from '../../Molecules/Button/Button';
import './ProductCard.scss';
let classNames = require('classnames');
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';

import { uniqueID } from '../../Utils/Utils'

function ProductCard({ card, rtl, htmlTag }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false)
  const openCard = () => {
    if (card?.img) {
      setOpen(true)
    }
  }
  const closeCard = () => {
    setOpen(false)
  }

  const html_inner_card = () => {
    return (
      <>
        <div className="img_wrapper">
          <div className="card_vector">
            {
              card?.img &&
              <div className="inner_img_wrapper">
                <img src={card?.img} alt={card?.alt} aria-hidden='true' />
                <MaqsueBannerCardProduct
                  className={classNames('shap_blue_banner')}
                  clipPath={uniqueID('_id_unique_')}
                />
              </div>
            }
            <div className="card_upper">
              <div className={classNames('card_txt', { 'text-rtl': rtl })}>
                {
                  card?.title_formatted ?
                    (
                      <h3 dangerouslySetInnerHTML={{ __html: card?.title_formatted.replace(/(<p[^>]+?>|<p>|<\/p>)/img, '') }} />
                    ) :
                    (
                      <h3>{card?.title}</h3>
                    )
                }

                <div className="icon">
                  <HorizontalYellowLineShape className={classNames(
                    'tirer'
                  )} />
                </div>
                <span className={classNames('subtitle')}>{card?.subTile}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="lower_wrapper">
          <div className="card_lower">
            <div className="card_lower_inner">
              <div className={classNames('wrapper_txt', { 'text-rtl': rtl })} dangerouslySetInnerHTML={{ __html: card.paragraph }} />
              {
                card.link?.url &&
                <div className={classNames('btn_wrapper', { 'text-rtl': rtl })}>
                  <Button
                    primary={true} label={card.link?.title || 'default'}
                    link={card.link?.url}
                    onFocus={() => openCard()} onBlur={() => setOpen(false)}
                    aria-label={intl.formatMessage({ id: 'common.find_more_about' }) + ' : ' + (card?.title || card?.title_formatted?.replace(/(<p[^>]+?>|<p>|<\/p>)/img, ''))}
                  />
                </div>
              }

            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      {
        htmlTag === 'li' ?
          (
            <li className={`card_wrapper ${!card.img ? 'no_img' : ''} ${open ? "open" : ""}`} tabIndex={0} onMouseEnter={() => openCard()} onMouseLeave={() => setOpen(false)} onFocus={() => openCard()} onBlur={() => setOpen(false)}>
              {html_inner_card()}
            </li>
          ) :
          (
            <div className={`card_wrapper ${!card.img ? 'no_img' : ''} ${open ? "open" : ""}`} tabIndex={0} onMouseEnter={() => openCard()} onMouseLeave={() => setOpen(false)} onFocus={openCard} onBlur={() => setOpen(false)}>
              {html_inner_card()}
            </div>
          )
      }
    </>
  );
}


export default ProductCard;
