import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { removeHtmlTag, getBreadCrumb, getTextColor, getSectionBG } from '../../../Utils/Utils';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { FormattedMessage, useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import useMedias from '../../../hooks/useMedias';
import PageBannerStyle from '../../../components/PageBannerStyle/PageBannerStyle';
import Dropdown from '../../../Molecules/Dropdown/Dropdown'
import CardSection from '../../../components/ContentType/CardSection/CardSection';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import VisuelComponent from '../../../components/ContentType/VisuelComponent/VisuelComponent';
import ArrowList from '../../../Molecules/ArrowList/ArrowList';
import PageBannerMap from '../../../components/PageBannerMap/PageBannerMap';
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";

let classNames = require('classnames');

const SavoirFairePage = ({ data }) => {
  const intl = useIntl();
  const { getImage } = useMedias();
  const [selectedDropDown, setSelectedDropdown] = useState(null);

  const imagesArray = data?.allImages?.edges;
  const savoirFaireData = data?.savoirFaireData?.edges[0]?.node;
  const metaTags = savoirFaireData.metatag;
  let metaTitle = '';
  let metaDesc = '';

  metaTags.forEach((meta) => {
    if (meta.attributes.name === 'title') {
      metaTitle = meta.attributes.content;
    }
    if (meta.attributes.name === 'description') {
      metaDesc = meta.attributes.content;
    }
  });
  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');

  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    setIsDesktop(window.innerWidth > 1023 ? true : false);
  }, []);

  let globalText = '';
  let innerNav = [];
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, savoirFaireData.path?.alias);

  useEffect(() => {
    setInnerNavList(innerNav);
    setAllText(
      removeHtmlTag(
        `${savoirFaireData.title}.${savoirFaireData.field_sous_titre !== null &&
          savoirFaireData.field_sous_titre !== undefined
          ? savoirFaireData.field_sous_titre
          : ''
        }.${savoirFaireData.body?.processed}.${savoirFaireData.field_text_2?.processed}.${globalText}`
      )
    );
  }, []);

  const onChangeDropdownBanner = (dropdownSelectedItem) => {
    const block_pos = dropdownlist.indexOf(dropdownSelectedItem);
    if (block_pos > -1) {
      const offset_top = document.querySelector('.scroll_to_' + (block_pos))?.offsetTop - 50;
      window.scrollTo({
        top: offset_top,
        behavior: 'smooth',
      });
    };
  }
  const dropdownlist = [];
  savoirFaireData.relationships?.field_blocs?.map((block, i) => {
    if (block.__typename != 'block_content__block_video' && block?.field_title?.processed) {
      dropdownlist.push(block?.field_title?.processed)
    }
  })
  return (
    <Layout>
      <Seo
        title={savoirFaireData?.field_metatag?.title ? savoirFaireData?.field_metatag?.title : metaTitle}
        description={savoirFaireData?.field_metatag?.description ? savoirFaireData?.field_metatag?.description : metaDesc}
      />
      <div className={classNames('page_template', 'savoir_faire_page')} >
        <ScrollPageNav data={innerNavList} />
        {isDesktop ?
          <PageBannerMap
            showHideClass={false}
            arrowIconeWhite={false}
            visuel={getImage(
              imagesArray,
              savoirFaireData?.relationships?.field_image?.drupal_internal__mid
            )}
            alt={savoirFaireData.relationships?.field_image?.field_media_image?.alt || ''}
            scroll={true}
            breadcrumbData={{
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: savoirFaireData?.title,
                url: '',
              },
              locale: savoirFaireData.langcode,
            }}
          >
            <div className={classNames('wrapper_page', { 'allorw_overflow': (dropdownlist.length > 0 && savoirFaireData.field_display_dropdown_list) })}>
              <div
                className={classNames({
                  title_audio:
                    allText &&
                    savoirFaireData.field_version_audio &&
                    allText.length > 30,
                })}
              >
                {' '}
                <TitlePage
                  color="color_white"
                  title={savoirFaireData?.title}
                  description={savoirFaireData?.field_sous_titre}
                />
                <div className="col_2_fix">
                  <div className="col">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: savoirFaireData.body?.processed,
                      }}
                    ></div>
                  </div>
                  <div className="col">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: savoirFaireData.field_text_2?.processed,
                      }}
                    ></div>
                  </div>
                </div>
                {allText &&
                  savoirFaireData.field_version_audio &&
                  allText.length > 30 && (
                    <AudioComponent
                      text={intl.formatMessage({
                        id: 'detailsActivities.button.listen.label',
                      })}
                      textToRead={allText}
                      color="color_white"
                    />
                  )}
                {
                  (dropdownlist?.length > 0 && savoirFaireData.field_display_dropdown_list) &&
                  <Combobox
                    defaultValue={intl.formatMessage({ id: 'common.choose' })}
                    data={dropdownlist}
                    onChange={onChangeDropdownBanner}
                    onKeyPress={(e) => { e.preventDefault(); }}
                    filter={false}
                  />
                }
                {
                  savoirFaireData.field_display_dropdown_list &&
                  <span role="status" className="sr-only"><FormattedMessage id="media.dropdown_sr_only" /></span>
                }

              </div>
            </div>
          </PageBannerMap>
          :
          <PageBannerStyle
            visuel={getImage(
              imagesArray,
              savoirFaireData?.relationships?.field_image?.drupal_internal__mid
            )}
            alt={savoirFaireData.relationships?.field_image?.field_media_image?.alt || ''}
            scroll={true}
            breadcrumbData={{
              parentPage: {
                title: breadCrumb?.parent?.name,
                url: breadCrumb?.parent?.link,
              },
              currentPage: {
                title: savoirFaireData?.title,
                url: '',
              },
              locale: savoirFaireData.langcode,

            }}
          >
            <div className={classNames('wrapper_page', { 'allorw_overflow': (dropdownlist.length > 0 && savoirFaireData.field_display_dropdown_list) })}>
              <div
                className={classNames({
                  title_audio:
                    allText &&
                    savoirFaireData.field_version_audio &&
                    allText.length > 30,
                })}
              >
                {' '}
                {/* !!!!!! if audio add title_autio if not put nothing */}
                <TitlePage
                  color="color_white"
                  title={savoirFaireData?.title}
                  description={savoirFaireData?.body?.processed}
                />
                {allText &&
                  savoirFaireData.field_version_audio &&
                  allText.length > 30 && (
                    <AudioComponent
                      text={intl.formatMessage({
                        id: 'detailsActivities.button.listen.label',
                      })}
                      textToRead={allText}
                      color="color_white"
                    />
                  )}
                {
                  (dropdownlist?.length > 0 && savoirFaireData.field_display_dropdown_list) &&
                  <Combobox
                    defaultValue={intl.formatMessage({ id: 'common.choose' })}
                    data={dropdownlist}
                    onChange={onChangeDropdownBanner}
                  />
                }
              </div>
            </div>
          </PageBannerStyle>}

        {savoirFaireData.relationships?.field_blocs?.map((block, i) => {
          globalText += `${block.field_title?.processed != undefined &&
            block.field_title?.processed != null
            ? block.field_title?.processed
            : ''
            }.${block.field_subtitle?.processed != undefined &&
              block.field_subtitle?.processed != null
              ? block.field_subtitle?.processed
              : ''
            }.${block.body?.processed != undefined && block.body?.processed != null
              ? block.body?.processed
              : ''
            }`;
          switch (block.__typename) {
            case 'block_content__block_video':
              const videoContent = {
                content: [
                  {
                    type: 'video',
                    link: block.field_link?.uri,
                    title: removeHtmlTag(block.field_title?.processed),
                    image: getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    ),
                    description: block.body?.processed
                  },
                ],
              };
              return <div className={classNames('pt-100', 'scroll_to_' + i)}><VideoSection imagesArray={imagesArray} videoDetails={videoContent} key={i}></VideoSection></div>;
            case 'block_content__basic':
              return (
                <section
                  className={classNames(
                    'section_content'
                  )}
                  key={i}
                >
                  <div className="wrapper_page_xs">
                    {
                      block.field_title_type === 'theme_2' ?
                        <TitleBlock
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          rtl={block?.field_display_rtl}
                          color={'color_dark_' + getTextColor(getSectionBG(block.field_backgroud_color))}
                          headingTag='h3'
                          titleBackground={false}
                        />
                        :
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          rtl={block?.field_display_rtl}
                          h2color={getTextColor(getSectionBG(block.field_backgroud_color))}
                          h4color={getTextColor(getSectionBG(block.field_backgroud_color))}
                          theme={block.field_title_type}
                        />
                    }
                    {
                      block.body?.processed &&
                      <ArrowList
                        textHtml={block.body?.processed}
                        rtl={block?.field_display_rtl}
                      />
                    }
                    {
                      block?.relationships?.field_image &&
                      <VisuelComponent
                        extremeLeft={
                          block.field_no_padding &&
                            block.field_alignment === 'left'
                            ? true
                            : false
                        }
                        extremeRight={
                          block.field_no_padding &&
                            block.field_alignment === 'right'
                            ? true
                            : false
                        }
                        visuel={getImage(
                          imagesArray,
                          block?.relationships?.field_image?.drupal_internal__mid
                        )}
                        size="two_third"
                        alt={block?.relationships?.field_image?.field_media_image?.alt || ''}
                      />
                    }
                  </div>
                </section>
              );
            case 'block_content__block_texte':
              innerNav.push({
                title: block.field_title?.processed,
                section: 'scroll_to_' + i,
              });

              switch (block.field_backgroud_color) {
                case 'white':
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'scroll_to_' + i,
                        'section_developement'
                      )}
                      key={i}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          description={removeHtmlTag(
                            block.field_subtitle?.processed || ''
                          )}
                          type="arrow"
                        />
                        {block.relationships?.field_section?.map((section, j) => {
                          globalText += `${section.relationships?.field_bloc_texte[0]
                            ?.field_title?.processed != undefined
                            ? section.relationships?.field_bloc_texte[0]
                              ?.field_title?.processed
                            : ''
                            }.${section.relationships?.field_bloc_texte[0]
                              ?.field_bloc_text_1.processed
                            }`;
                          return (
                            <TextVisuelTwoCol
                              key={j}
                              orderInverse={
                                section?.field_alignment === 'right'
                                  ? true
                                  : false
                              }
                              extremeLeft={
                                section?.field_alignment === 'left' &&
                                  section?.field_no_padding
                                  ? true
                                  : false
                              }
                              extremeRight={
                                section?.field_alignment === 'right' &&
                                  section?.field_no_padding
                                  ? true
                                  : false
                              }
                              visuel={getImage(
                                imagesArray,
                                section?.relationships?.field_image
                                  ?.drupal_internal__mid
                              )}
                              alt={section.relationships?.field_image?.field_media_image?.alt || ''}
                              title={
                                section.relationships?.field_bloc_texte[0]
                                  ?.field_title?.processed
                              }
                              text={
                                section.relationships?.field_bloc_texte[0]
                                  ?.field_bloc_text_1?.processed
                              }
                              alignTop={true}
                            />
                          );
                        })}
                      </div>
                    </section>
                  );

                case 'ice':
                case 'navy':
                  return (
                    <section
                      className={classNames(
                        'section_content',
                        'scroll_to_' + i,
                        'section_climate_issues'
                      )}
                      key={i}
                    >
                      <ShapeBackground
                        top="left"
                        color={getSectionBG(block?.field_backgroud_color)}
                        bottomInfinite={true}
                      >
                        <div className="full_page_wrapper">
                          <div className="wrapper_page_xs">
                            <TitleSection
                              title={block.field_title?.processed}
                              description={removeHtmlTag(
                                block.field_subtitle?.processed != undefined
                                  ? block.field_subtitle?.processed
                                  : ''
                              )}
                              type="arrow"
                              h2color={getTextColor(getSectionBG(block?.field_backgroud_color))}
                            />
                          </div>
                          <VisuelTextOverlappedBlock
                            extremeLeft={true}
                            visuel={getImage(
                              imagesArray,
                              block?.relationships?.field_image
                                ?.drupal_internal__mid
                            )}
                            bgColor={block.field_backgroud_color === 'ice' ? "color_dark_bleu" : 'white'}
                            alt={block.relationships?.field_image?.field_media_image?.alt || ''}
                            text={block?.body?.processed}
                          />
                          <div className="wrapper_page_xs">
                            {block.relationships?.field_section?.map(
                              (section, j) => {
                                globalText += `${section.relationships?.field_bloc_texte[0]
                                  ?.field_title?.processed != undefined
                                  ? section.relationships?.field_bloc_texte[0]
                                    ?.field_title?.processed
                                  : ''
                                  }.${section.relationships?.field_bloc_texte[0]
                                    ?.field_bloc_text_1.processed
                                  }`;
                                return (
                                  <TextVisuelTwoCol
                                    key={j}
                                    orderInverse={
                                      section?.field_alignment === 'right'
                                        ? true
                                        : false
                                    }
                                    extremeLeft={
                                      section?.field_alignment === 'left' &&
                                        section?.field_no_padding
                                        ? true
                                        : false
                                    }
                                    extremeRight={
                                      section?.field_alignment === 'right' &&
                                        section?.field_no_padding
                                        ? true
                                        : false
                                    }
                                    visuel={getImage(
                                      imagesArray,
                                      section?.relationships?.field_image
                                        ?.drupal_internal__mid
                                    )}
                                    alt={section.relationships?.field_image?.field_media_image?.alt || ''}
                                    title={
                                      section.relationships?.field_bloc_texte[0]
                                        ?.field_title?.processed
                                    }
                                    text={
                                      section.relationships?.field_bloc_texte[0]
                                        ?.field_bloc_text_1?.processed
                                    }
                                    colorTitle={block.field_backgroud_color === 'ice' ? "color_dark_bleu" : 'color_white'}
                                    colorDescription={block.field_backgroud_color === 'ice' ? "color_dark_bleu" : 'color_white'}
                                  />
                                );
                              }
                            )}
                          </div>
                        </div>
                      </ShapeBackground>
                    </section>
                  );
              };
            case 'block_content__block_solutions':
              return <section
                className={classNames(
                  'section_content',
                  'block_2_section',
                  'scroll_to_' + i
                )}
                key={i}
              >
                <ShapeBackground
                  top={
                    block.field_position_bordure_haut === 'gauche'
                      ? 'left'
                      : block.field_position_bordure_haut != null &&
                      'right'
                  }
                  color={
                    block.field_backgroud_color === 'ice'
                      ? 'catskill_white'
                      : block.field_backgroud_color === 'navy'
                        ? 'dark_bleu'
                        : 'white'
                  }
                  bottomInfinite={block.field_infinite_border}

                  bottom={
                    block.field_position_bordure_bas === 'gauche'
                      ? 'left'
                      :
                      block.field_position_bordure_bas != null &&
                      'right'
                  }
                >
                  <CardSection
                    imagesArray={imagesArray}
                    section={block}
                    counter={1}
                    listType="solutions"
                  />
                </ShapeBackground>
              </section>;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SavoirFaireQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
  ) {
    savoirFaireData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          path {
            alias
            langcode
          }
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          field_sous_titre
          body {
            processed
          }
          field_text_2 {
            processed
          }
          field_version_audio
          field_display_dropdown_list
          field_type
          relationships {
            field_image {
              drupal_internal__mid
              field_media_image{
                alt
              }
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__block_texte {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_bloc_text_1 {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                    field_section {
                      field_no_padding
                      relationships {
                        field_image {
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                        }
                        field_bloc_texte {
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                        }
                      }
                      field_alignment
                    }
                  }
                  body {
                    processed
                  }
                  field_backgroud_color
                }
                ... on block_content__block_video {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_link {
                    uri
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                }
                ... on block_content__block_solutions{
                  field_title{
                    processed
                  }
                  field_subtitle{
                    processed
                  }
                  field_display_rtl
                  field_position_bordure_haut
                  field_position_bordure_bas
                  field_infinite_border
                  field_backgroud_color
                  relationships{
                    field_blocs{
                      field_subtitle
                      field_text{
                        processed
                      }
                      field_bloc_text_1{
                        processed
                      }
                      relationships{
                        field_image{
                          drupal_internal__mid
                          field_media_image{
                            alt
                          }
                          field_alt
                        }
                      }
                      field_link{
                        title
                        url
                      }
                    }
                  }
                  
                }
                ... on block_content__basic {
                  id
                  relationships {
                    field_image {
                      drupal_internal__mid
                      field_media_image{
                        alt
                      }
                    }
                  }
                  field_link {
                    title
                    url
                  }
                  field_display_rtl
                  field_no_padding
                  field_alignment
                  field_subtitle {
                    processed
                  }
                  field_title_type
                  field_title {
                    processed
                  }
                  body{
                    processed
                  }
                  field_alignment
                }

              }
            }
          }
          langcode
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
              image_style_uri {
                gatsby_thumbnail
                gatsby_medium
                gatsby_large
                gatsby_wide
                gatsby_medium_carousel
                gatsby_thumbnail_carousel
                gatsby_innov_carousel
                gatsby_activites_carousel
                gatsby_profile_large
                gatsby_profile_thumbnail
                webp
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

export default SavoirFairePage;
