import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import BGArrowPointerBottomMobile from '../../assets/images/bg-page-banner-v2-bottom-mobile.inline.svg';
import BGArrowPointerBottomLeft from '../../assets/images/bg-page-banner-v2-bottom.inline.svg';
import BGArrowPointerTopMobile from '../../assets/images/bg-page-banner-v2-top-mobile.inline.svg';
import BGArrowPointerTopLeft from '../../assets/images/bg-page-banner-v2-top.inline.svg';
import { ShapeYellowArrowBanner, IconDeploySelector, ArrowIconGoLeft, ArrowYellow } from '../../Atoms/Icons/Icons';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import './PageBannerMap.scss';
import useMedias from '../../hooks/useMedias';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
let classNames = require('classnames');

const PageBannerMap = ({ breadcrumbData, returnWordMap, iframeURL, children, visuel, showHideClass, arrowIconeWhite }) => {
  const intl = useIntl();

  const { processUrl } = useMedias();
  const [fullVisuel, setFullVisuel] = useState(false);
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', onPageScroll);
    return () => window.removeEventListener('scroll', onPageScroll);
  }, []);

  useEffect(() => {
    setShowMap(fullVisuel);
  }, [fullVisuel]);

  const onPageScroll = () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll > 20) {
      setFullVisuel(false);
    }
  };

  const onClickVIsuelOverlay = (e) => {
    if (e) { e.preventDefault() }
    setFullVisuel(true);
    window.scrollTo(0, 0);
  };

  const onClickScrollIcon = (e) => {
    e.preventDefault();
    setFullVisuel(!fullVisuel);
  };

  return (
    <div className="page_banner_map">
      <div className={classNames('visuel_container', { 'full_size': showMap })}>
        <div className="visuel">
          {
            iframeURL && (
              <iframe id="mapiframe" src={iframeURL} width="100%" height="100%" />
            )
          }
          {visuel && (
            <>
              <img
                className="only_desktop"
                src={processUrl(visuel?.image_style_uri?.gatsby_wide)}
                style={{ height: '100%', objectFit: 'cover' }}
                aria-hidden="true"
              />
              <img
                className="only_mobile_tablet"
                src={processUrl(visuel?.image_style_uri?.gatsby_medium)}
                style={{ height: '100%', objectFit: 'cover' }}
                aria-hidden="true"
              />
            </>
          )}

        </div>
        {
          returnWordMap &&
          <Link to={returnWordMap.url} className="nav_return_map">
            <ArrowIconGoLeft color="color_yellow" />
            <p>{returnWordMap.text}</p>
          </Link>
        }

        <div className={classNames('visuel_overlay', { 'hide': showHideClass && showMap })} onClick={onClickVIsuelOverlay}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={breadcrumbData}
            />
          </div>
        </div>
      </div>
      <div className={classNames('intro_design', { 'fullVisuel': showMap })}>
        <div className={classNames('shaped_background_banner')}>
          <div className={classNames('bg_arrow_pointer_top')}>
            <BGArrowPointerTopMobile className="only_mobile_tablet" />
            <BGArrowPointerTopLeft className="only_desktop" />
          </div>
          {arrowIconeWhite ? (
            <a href="#" className={classNames('btn_chevron', { 'up': showMap })}
              aria-label={`${showMap ? intl.formatMessage({ id: 'ariaLabel.minimize_the_visual' }) : intl.formatMessage({ id: 'ariaLabel.show_visual' })}`}
            >
              <IconDeploySelector
                aria-hidden="true"
                className="chevron"
                onClick={(e) => onClickScrollIcon(e)}
              />
            </a>
          ) : (

            <a href="#" role='button' className={classNames('btn_chevron yellow', 'test', { 'up': showMap })}
              aria-label={`${showMap ? intl.formatMessage({ id: 'ariaLabel.minimize_the_visual' }) : intl.formatMessage({ id: 'ariaLabel.show_visual' })}`}
              onClick={(e) => onClickScrollIcon(e)}>
              <ArrowYellow
                className="chevron"

              />
            </a>
          )}

          <div className="center_content">
            {children}
          </div>
          <div className={classNames('bg_arrow_pointer_bottom')}>
            <BGArrowPointerBottomMobile className="only_mobile_tablet" />
            <BGArrowPointerBottomLeft className="only_desktop" />
          </div>
        </div>
        <ShapeYellowArrowBanner className="shape_yellow_banner_bottom" />
      </div>
    </div>
  );
};

export default PageBannerMap;
